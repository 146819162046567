import React from "react";
import Carousel from "react-bootstrap/Carousel";

import promocionAlarma1 from "../assets/img/promo.png";
import promocionAlarma1Mobile from "../assets/img/promoMobile.png";

import "../assets/css/slider.css";

export const Slider = () => {
  const text = "Recibe asesoramiento sin cargo";

  return (
    <div className="slider-title">
      <div className="carousel">
        <Carousel>
          <Carousel.Item>
            {window.innerWidth <= 1001 ? (
              <img
                src={promocionAlarma1Mobile}
                alt={text}
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <img
                src={promocionAlarma1}
                alt={text}
                className="d-block w-100"
              />
            )}
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
