import React from 'react'
import "../assets/css/politicasPrivacidad.css";
import "../assets/css/App.css";
import { Logo } from "../components/Logo";


const PoliticasPrivacidad = () => {
  return (
    <div>
        <div className="fullPage">
          <div className="top-bar">
              <Logo />
          </div>
        </div>
        <div className='container'>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className='policies-title'>
            Política de privacidad y protección de datos
            </h1>
            <br/>
            <p>
            Este aviso legal es de aplicación a las páginas de los portales web y sedes electrónicas propiedad del ADT Alarmas - Distribuidor Oficial. El ADT Alarmas - Distribuidor Oficial actúa en calidad de responsable del tratamiento de los datos de carácter personal relativos a los usuarios de sus servicios. Los datos aportados por el interesado se utilizarán, con carácter exclusivo, para los fines previstos en cada procedimiento o actuación.
            </p>
            <p>
            La normativa vigente en el ámbito de la protección de datos de carácter personal se compone del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril del 2016 (RGPD) y de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (LOPD). De acuerdo con la normativa anteriormente citada, el Ministerio garantizará la confidencialidad de los datos personales tratados. Para ello, adoptará las medidas técnicas y organizativas necesarias.
            </p>
            <br/>
            <h3>
            Recogida de datos personales
            </h3>
            <br/>
            <h4>
            Destinatarios, cesión y transferencia de datos
            </h4>
            <br/>
            <p>
            En la mayoría de los casos el ADT Alarmas - Distribuidor Oficial no comunica los datos personales tratados a otros destinatarios. En los casos en que se comunican a terceros, siempre se procede en aplicación de la normativa vigente, informando cuando proceda al interesado.
            </p>
            <h4>
            Período de conservación de los datos 
            </h4>
            <br/>
            <p>
            Los datos se conservarán durante el tiempo imprescindible para garantiza la prestación de los servicios ofrecidos por el ADT Alarmas - Distribuidor Oficial.
            </p>
            <br/>
            <h4>
            Ejercicio de derechos
            </h4>
            <br/>
            <p>
            El usuario podrá ejercitar en todo momento los derechos reconocidos en relación con los datos tratados por el ADT Alarmas - Distribuidor Oficial. Para ello, podrá presentar en la Sede Electrónica del ADT Alarmas - Distribuidor Oficial una solicitud de Acceso, Limitación, Rectificación, Portabilidad, Supresión, u Oposición, de acuerdo con la normativa vigente.
            </p>
            <br/>
            <h3>
            Ejercicio de los derechos de protección de los datos personales
            </h3>
            <br/>
            <p>
            También podrá dirigirse a la autoridad de control, en este caso ​​​​​​​ Agencia Española de Protección de Datos, e interponer una reclamación si considera que sus datos personales no se tratan conforme a la normativa vigente.
            </p>
            <br/>
            <h4>
            Quejas y sugerencias
            </h4>
            <br/>
            <p>
            Con objeto de recoger y tramitar tanto las manifestaciones de insatisfacción con los servicios prestados como las iniciativas para mejorar su calidad, podrá presentar en la Sede Electrónica del ADT Alarmas - Distribuidor Oficial una queja o sugerencia.
            </p>
            <br/>
            <h4>
            Política de cookies
            </h4>
            <br/>
            <p>
            El ADT Alarmas - Distribuidor Oficial utiliza un servicio de analítica web consistente en instalar cookies temporalmente con la única y exclusiva finalidad de elaborar informes de carácter estadístico. Las cookies son ficheros creados en el navegador del usuario para registrar su actividad en el sitio web. Una cookie no identifica a una persona, sino una combinación computador-navegador-usuario, tiene una vigencia temporal limitada y en ningún caso se utiliza para recoger información de carácter personal.
            </p>
            <p>
            Para navegar por el portal y las sedes electrónicas del Ministerio se puede optar por no permitir la instalación de estas cookies, y es posible deshabilitarlas directamente en el navegador. En cada navegador la operativa es diferente. En la ayuda del navegador encontrara información relacionada con el uso de las cookies.
            </p>
            <br/>
        </div>
    </div>
  )
}

export default PoliticasPrivacidad