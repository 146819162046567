import React from 'react'
import "../assets/css/smartPanel.css";
import smartPanel from "../assets/img/smartPanel.png";

const SmartPanel = () => {
  return (
    <div className='smart-panel'>
        <h4>Nuevo</h4>
        <h1 className='panel-title'>Panel de control inteligente</h1>
        <div className='position-smart-panel'>
            <img
                src={smartPanel}
                alt='SmartPanel'
            />
            <div className='position-content-panel'>
                <h1 className='new-fuctions'>
                Descubre todas las funciones del cerebro del sistema:
                </h1>
                <ul>
                    <li>Se comunica con los dispositivos vinculados.</li>
                    <li>Detecta rotura de cristales.</li>
                    <li>Avisa a la CRA a través de 4G LTE y Multivía conexión mediante WIFI.</li>
                    <li>Dispone de sirena integrada y tecnología de detección de inhibidores.</li>
                    <li>Incorpora batería ante cortes de red eléctrica.</li>
                    <li>Ofrece una experiencia interactiva.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default SmartPanel