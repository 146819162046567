import React from "react";
import nav from '../assets/img/logo.png'
import '../assets/css/logo.css'

export const Logo = () => {
  return (
      <div className="logo">
        <img src={nav} alt="logo" />
      </div>
  );
};

