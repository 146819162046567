import React from 'react'
import "../assets/css/whyChooseUs.css";
import smartPanel from "../assets/img/familia.png";

const WhyChooseUs = () => {
  return (
    <div className='why-choose-us'>
        <div className='why-choose-us-container'>
            <div className='why-choose-us-content'>
                <h1 className='why-choose-us-title'>
                    ¿Por qué elegir ADT?
                </h1>
                <p>
                Somos líderes mundiales en seguridad 
    electrónica y monitoreo de alarmas. 
    Contamos con más de 145 años de experiencia y presencia en más de 60 países. Brindamos un servicio de protección integral las 24 horas protegiendo lo más importante para nuestros clientes, con la más moderna tecnología. Ofrecemos soluciones adaptadas a cada necesidad, ya sean hogares o pequeños comercios.
                </p>
            </div>
            <div className='why-choose-us-img-position'>
                <img
                    src={smartPanel}
                    alt='app-smart-security'
                />
            </div>
        </div>
    </div>
  )
}

export default WhyChooseUs